<!-- @format -->
<template>
  <header
    class="h-16 2xl:px-10 bg-cyan-500 text-white text-primary-content sticky top-0 shadow-md z-50"
  >
    <div class="p-2 mx-auto text-left flex w-full items-center">
      <div class="text-3xl flex-1 text-white uppercase">
        Sandbox-08 <span class="text-lg">Market</span>
        <!-- <div class="flex h-12">
          <img src="@/assets/stevenson.jpg" class="object-cover" />          
        </div> -->
      </div>
      <div v-if="!isAuthenticated" class="flex gap-2">

        <FlatMiniButton backgroundColor="bg-lime-100 text-fuchsia-700"
          @click="createAccount"
        >Create Account</FlatMiniButton>

        <FlatMiniButton backgroundColor="bg-white text-fuchsia-700" @click="login">Login</FlatMiniButton>
      </div>
      <template v-else>
        <div class="h-8 w-8 border border-white rounded-full flex overflow-hidden">
          <img :src="getUserData.profile_photo_url" class="object-cover" />
        </div>

        <div class="pl-2 text-sm font-bold cursor-pointer" @click="logoutAction">
          Logout
        </div>
      </template>
    </div>
  </header>
  <div class="flex 2xl:px-10">
    <aside class="h-screen sticky top-16 w-80">
      <slot name="sidebar"></slot>
    </aside>
    <div @loginAction="login">
      <slot></slot>
    </div>
  </div>
  <RegistrationForm v-model:showOn="showRegistForm" @onSuccess="onRegistrationCompleted" />
</template>
<script>
//import { checkAuth } from '@/data/pandai.auth';
import FlatMiniButton from "@/components/FlatMiniButton.vue";
import RegistrationForm from "@/views/RegistrationForm.vue";
import { mapGetters, mapActions } from "vuex";
import { authUri } from "@/data/appdata";
export default {
  data() {
    return {
      test: null,
      showRegistForm: false,
    };
  },
  computed: {
    ...mapGetters(["getUserData", "getAuthToken", "isAuthenticated", "regState"]),
    authEndPoint() {
      return authUri;
    },
  },
  watch: {
   /*  getAuthToken: function (v) {
      console.log(v);
    }, */
    regState:function(v){        
        if(!v){
          this.showRegistForm = false;
        }
        this.showRegistForm = true;
      
    }
  },
  methods: {
    
    ...mapActions(["logoutAction", "invokeRegister"]),
    async onRegistrationCompleted(data){
      let token = data.token;
      let user = data.user;      
      await this.$store.dispatch('saveAuthToken', { token });
      await this.$store.dispatch('setUserdata', {data:user});
      this.showRegistForm = false;
      this.setUserData(user);
    },
    login() {
      //const authUri = 'http://pandaisuite.local/oauth/authorize_access?target=http://192.168.111.40:8080/pandai_auth/';
      /*       var w = 460;
            var h = 540;
            var left = (screen.width) - w;
            var top = (screen.height/2)-(h/2);
            return window.open(authUri, 'Login With PandaiSuite', 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left); */
      return window.open(this.authEndPoint, "newwindow", "width=500, height=500");
    },
    createAccount() {
      //console.log('test2')
      return this.$store.dispatch('invokeRegister', {data: new Date()})
    }
  },
  /* async created() {
      const data = await checkAuth();
      console.log(data);
  }, */
  components: { FlatMiniButton, RegistrationForm },
};
</script>
<style>
</style>
